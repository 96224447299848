import React from "react";
import { Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Facebook from "../Assets/img/facebook.svg";
import Twitter from "../Assets/img/twitter.svg";
import Linkedin from "../Assets/img/linkedin.svg";

const Footer = () => {
	return (
		<div className="footer-sec">
			<Row
				className="justify-content-md-center"
				style={{ margin: "0px" }}
			>
				<Col xs lg="10" className="footer-col" id="footer-divs">
					<div className="links">
						<p>
							Copyright © 2021 Sanyu Realty. All rights reserved.
						</p>
						{/* <Router>
                            <Link className="thelink" href="/" to={"/"}>
                                Privacy
                            </Link>
                            <Link className="thelink" href="/" to={"/"}>
                                Terms of Service
                            </Link>
                        </Router> */}
					</div>
					<div className="social-div">
						<Router>
							<a
								className="social"
								href="https://www.facebook.com/SanyuRealty/"
								target="_blank"
							>
								<div className="about-more">
									<img src={Facebook} alt="bacebook" />
								</div>
							</a>
							<Link className="social" href="/" to={"/"}>
								<div className="about-more">
									<img src={Twitter} alt="twitter" />
								</div>
							</Link>
							<Link className="social" href="/" to={"/"}>
								<div className="about-more">
									<img src={Linkedin} alt="linkedin" />
								</div>
							</Link>
						</Router>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default Footer;
