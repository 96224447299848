import React from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

import "../Assets/css/articleDescription.css";

function ArticleDescription({ article }) {
	return (
		<div className="article-descr">
			<Row
				className="justify-content-md-center"
				style={{ margin: "0px" }}
			>
				<Col xs lg="10">
					<div className="flexdivs">
						<div className="main-descr">
							<div className="article-title">
								<h3>
									{moment(article.created).format(
										"YYYY-MM-DD"
									)}
								</h3>
								<h1>{article.title}</h1>
							</div>
							<div>
								<div
									dangerouslySetInnerHTML={{
										__html: article.description,
									}}
								/>
							</div>
						</div>
					</div>
					<div className="sep-item">
						<img
							src={
								article
									? `${process.env.REACT_APP_API_URL}blog/image/${article?.images[0]?.imageName}`
									: ""
							}
							alt="house 8"
							className="img-fluid"
						/>
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default ArticleDescription;
