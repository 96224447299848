import React, { useEffect, useState } from "react";
import "../Assets/css/allProperties.css";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import ModalInfo from "../Components/modalInfo";
import PropPagination from "../Components/propPagination";
import { LoopCircleLoading } from "react-loadingg";

const AllProperties = () => {
	const [properties, setProperties] = useState([]);
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(6);

	// useEffect(() => {
	//   const fetchPosts = async () => {
	//     setLoading(true);
	//     const res = await axios
	//       .get(`${process.env.REACT_APP_API_URL}statements/all_property`)
	//       .then(({ data: { value } }) => {
	//         setProperties(value);
	//       });
	//     setLoading(false);
	//   };
	//   fetchPosts();
	// }, []);

	useEffect(() => {
		const script = document.createElement("script");
		script.id = "idxbroker";
		script.src =
			"https://sanyurealty.idxbroker.com/idx/customshowcasejs.php?widgetid=10857";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(document.querySelector("#idxbroker"));
			document.body.removeChild(
				document.querySelector(".IDX-showcaseTable")
			);
		};
	}, []);

	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = properties.slice(indexOfFirstPost, indexOfLastPost);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	if (loading) {
		return (
			<div className="loader">
				<LoopCircleLoading color="#3895d3" />
			</div>
		);
	}

	return (
		<div className="allProperties">
			<div>
				{show ? (
					<ModalInfo show={show} onHide={() => setShow(false)} />
				) : null}

				<div className="latestprop">
					<Row
						name="top"
						className="justify-content-md-center"
						style={{ margin: "0px" }}
					>
						<Col xs lg="10">
							<div className="show-all">
								<div className="latest-title">
									<h1 style={{ color: "#3895D3" }}> All</h1>
									<h2>PROPERTIES</h2>
								</div>
							</div>
							<div className="blocks">
								<Row style={{ margin: "0" }} id="block1">
									{currentPosts.map((item) => {
										//console.log(item.ListingId);
										return (
											<Col
												key={item.ListingId}
												id="allcols"
												lg="4"
												md="4"
												sm="6"
												xs="12"
												style={{ marginBottom: "20px" }}
											>
												<ModalInfo item={item} />
											</Col>
										);
									})}
								</Row>
							</div>

							<PropPagination
								postsPerPage={postsPerPage}
								totalPosts={properties.length}
								paginate={paginate}
							/>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default AllProperties;
