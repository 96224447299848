import React, { useState } from "react";
import ArrowRight from "../Assets/img/arrow-alt-right.svg";
import { Button, Accordion, Card } from "react-bootstrap";

const AboutBtn = () => {
  const [isReadMore, setIsReadMore] = useState(true);
  const [isRotate, setIsRotate] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    setIsRotate(!isRotate);
  };
  return (
    <div className="about-txt">
      <Accordion>
        <Card>
          <p>
            My name is Victor Lukandwa. I have been a real estate professional
            for over 15 years. I help my clients navigate the home buying and
            selling process with the patience that they need to understand the
            process.
          </p>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="text">
              I help my clients to maximize their earnings when they sell and
              secure their dream home when they buy. As a software engineer, I
              utilize technology to the fullest to help my clients to benefit
              from the advantages tech provides, like market analytics, and
              helping them understand how to use it to their benefit. At Sanyu
              Realty, we help you find your dream home or investment. We have
              got knowledgeable agents that you can trust to have your best
              interests at heart. Give us a call and let us guide you in your
              real estate needs (651) 334-4806.
            </Card.Body>
          </Accordion.Collapse>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              eventKey="0"
              className="showbtn"
              onClick={toggleReadMore}
            >
              {isReadMore ? "read more" : "show less"}

              {isReadMore ? (
                <img src={ArrowRight} alt="arrow right" />
              ) : (
                <img src={ArrowRight} alt="arrow right" id="rotateImg" />
              )}
            </Accordion.Toggle>
          </Card.Header>
        </Card>
      </Accordion>
    </div>
  );
};

export default AboutBtn;
