import React, { useEffect, useState } from "react";
import ArrowRight from "../Assets/img/arrow-alt-right.svg";
import { Button, Row, Col } from "react-bootstrap";
import Arrow from "../Assets/img/more.svg";
import "../Assets/css/blogArticle.css";
import axios from "axios";
import ReactReadMoreReadLess from "react-read-more-read-less";
import BlogBackground from "../Assets/img/broker.jpg";
import { NavLink } from "react-router-dom";

const BlogArticle = () => {
	const [articles, setArticles] = useState([]);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}blog`)
			.then(({ data }) => {
				console.log(data);
				setArticles(data.splice(0, 6));
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div className="latestprop" style={{ marginTop: "70px" }}>
			<Row
				className="justify-content-md-center"
				style={{ margin: "0px" }}
			>
				<Col xs lg="10">
					<div className="show-all">
						<div className="latest-title">
							<h2>BLOG</h2>
						</div>
						<div className="showdiv">
							<Button
								href={"/blog"}
								className="showbtn"
								variant="secondary"
								size="lg"
							>
								Show all
								<img src={ArrowRight} alt="arrow right" />
							</Button>
						</div>
					</div>
					<div>
						<Row
							className="justify-content-md-center"
							style={{ margin: "0", paddingTop: "20px" }}
							id="article-row"
						>
							{articles.map((item) => {
								const mainImage = item.images.find(
									(image) => image.isMain
								);
								return (
									<Col
										xs
										lg="4"
										key={item.id}
										style={{ marginBottom: "20px" }}
										className="homepage-blog"
									>
										<div
											className="recart-block1"
											style={{
												backgroundImage: mainImage
													? `url(${
															process.env
																.REACT_APP_API_URL
													  }blog/image/${
															mainImage?.imageName ||
															BlogBackground
													  })`
													: BlogBackground,
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover",
											}}
										>
											<div className="descr-article">
												<p className="descr-text">
													{item.title}
												</p>
												<div className="about-article">
													<div className="new-invest">
														<p>new | {item.tag}</p>
													</div>
													<div className="date-article">
														<p>
															<ReactReadMoreReadLess
																charLimit={10}
																readMoreText={
																	""
																}
															>
																{item.created}
															</ReactReadMoreReadLess>
														</p>
														<NavLink
															to={`/blog/${item.id}`}
														>
															<span>
																<img
																	src={Arrow}
																	alt="arrow"
																/>
															</span>
														</NavLink>
													</div>
												</div>
											</div>
										</div>
									</Col>
								);
							})}
						</Row>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default BlogArticle;
