import React, { useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "../Assets/css/latestproperty.css";
import Bed from "../Assets/img/bed.svg";
import Bath from "../Assets/img/bath.svg";
import Area from "../Assets/img/area.svg";
import Heart from "../Assets/img/heart.svg";
import More from "../Assets/img/more.svg";
import { Button, Row, Col, Modal } from "react-bootstrap";
import Logo from "../Assets/img/logo.png";
import Maps from "../Assets/img/map.svg";
import Share from "../Assets/img/share.svg";
import Woman from "../Assets/img/woman.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import leftArrow from "../Assets/img/leftArrow.svg";
import rightArrow from "../Assets/img/rightArrow.svg";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import emailjs from "emailjs-com";

const ModalInfo = ({ item }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [sent, setSent] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `AIzaSyAsCZCqCPKyBEIwAUFt6alOItZVypKaD6M`,
  });
  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const containerStyle = {
    width: "100%",
    height: "350px",
    borderRadius: "25px",
    marginTop: "25px",
  };

  const center = {
    lat: item.Latitude,
    lng: item.Longitude,
  };

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_udrz95u",
        e.target,
        "user_TugD2zeJ6m80xGoPoNuwx"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setSent(true);
  }

  const arrowStyles = {
    position: "absolute",
    margin: "35px 10px",
    zIndex: 2,
    bottom: 20,
    width: 50,
    height: 50,
    cursor: "pointer",
    backgroundColor: "#fff",
    opacity: 0.8,
    borderRadius: "30px",
    border: "none",
  };

  return (
    <>
      <div className="block">
        <div className="main-img">
          <img src={item.Media[0].MediaURL} alt="house 1" />
          <span>Pending</span>
        </div>
        <div className="block-content">
          <div className="block-title">
            <h5>{item.BuildingName}</h5>
            <p>For Sale</p>
          </div>
          <p className="blockp">
            <ReactReadMoreReadLess charLimit={100} readMoreText={""}>
              {item.PublicRemarks}
            </ReactReadMoreReadLess>
            &nbsp;
            <span
              onClick={() => setShow(true)}
              style={{
                fontSize: "16px",
                color: "#3895d3",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              Read More
            </span>
          </p>

          <div className="descr">
            <img src={Bed} alt="bed" />
            <span>{item.MainLevelBathrooms}</span>
            <img src={Bath} alt="bath" />
            <span>{item.BedroomsTotal}</span>
            <img src={Area} alt="area" />
            <span>
              {item.LotSizeSquareFeet}
              &nbsp;Sq
            </span>
          </div>
          <div className="price-more">
            <div className="price">
              <h5>${item.OriginalListPrice}</h5>
            </div>
            <div className="about-more">
              <Router>
                <Link to={"/"}>
                  <img src={Heart} alt="heart" />
                </Link>
                <Link to={"/"} onClick={() => setShow(true)}>
                  <img src={More} alt="more" />
                </Link>
              </Router>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <div className="mod-head">
            <div className="mod-logo">
              <img src={Logo} alt="logo" />
            </div>
            <div className="about-more">
              <img src={Heart} alt="heart" />
              <img src={Maps} alt="map" />
              <img src={Share} alt="share" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="mod-body">
            <div className="body-content">
              <Row>
                <Col className="col-lg-4">
                  <div className="body-left">
                    <h3>{item.BuildingName}</h3>
                    <h6>
                      {item.PostalCode}, &nbsp;
                      {item.City}, &nbsp;
                      {item.Country}
                    </h6>
                    <p>{item.PublicRemarks}</p>
                    <div className="mod-description">
                      <div className="mod-descr">
                        <div>
                          <img src={Area} alt="area" />
                          <p>Living Area:</p>
                        </div>
                        <div>
                          <img src={Bed} alt="bed" />
                          <p>Bedrooms:</p>
                        </div>
                        <div>
                          <img src={Bath} alt="bath" />
                          <p>bathrooms:</p>
                        </div>
                      </div>
                      <div className="mod-descr2">
                        <h6>
                          {item.LotSizeSquareFeet}
                          &nbsp;Sq
                        </h6>
                        <h6>{item.MainLevelBathrooms}</h6>
                        <h6>{item.BedroomsTotal}</h6>
                      </div>
                    </div>
                    <h2 style={{ paddingBottom: "15px" }}>
                      ${item.OriginalListPrice}
                    </h2>
                    <div className="agent">
                      <div className="agent-name">
                        <div className="name-img">
                          <img src={Woman} alt="woman" />
                        </div>
                        <div className="info-name">
                          <h5>Agent</h5>
                          <h4>Victor Lukandwa</h4>
                        </div>
                      </div>
                      <div className="agentContact">
                        <h5>Contact Agent</h5>
                        {!sent ? (
                          <form className="contact-form" onSubmit={sendEmail}>
                            <div className="form-group">
                              <input
                                type="text"
                                name="from_name"
                                className="form-control"
                                placeholder="Name"
                                required
                              />

                              <input
                                type="email"
                                name="from_email"
                                placeholder="Your Email"
                                className="form-control"
                                required
                              />

                              <input
                                type="text"
                                name="from_number"
                                className="form-control"
                                placeholder="Phone"
                                required
                              />

                              <textarea
                                name="message"
                                rows="3"
                                placeholder="Massage"
                                className="form-control"
                                required
                              />
                              <button
                                type="submit"
                                className="btn btn-primary float-right"
                              >
                                contact agent
                              </button>
                            </div>
                          </form>
                        ) : (
                          <div className="mail-sent">
                            <h3>Your Message Send Successfully</h3>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="col-lg-8">
                  <div className="rightBar">
                    <Carousel
                      infiniteLoop
                      autoPlay
                      showArrows={true}
                      showThumbs={true}
                      renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                          <Button
                            type="button"
                            onClick={onClickHandler}
                            title={label}
                            style={{
                              ...arrowStyles,
                              left: 15,
                            }}
                          >
                            <img
                              src={leftArrow}
                              alt="Left Arrow"
                              className="arrowBtn"
                            />
                          </Button>
                        )
                      }
                      renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                          <Button
                            type="button"
                            onClick={onClickHandler}
                            title={label}
                            style={{
                              ...arrowStyles,
                              right: 15,
                            }}
                          >
                            <img
                              src={rightArrow}
                              alt="Left Arrow"
                              className="arrowBtn"
                            />
                          </Button>
                        )
                      }
                    >
                      <div className="image">
                        <img src={item.Media[0].MediaURL} alt="house" />
                      </div>
                      <div className="image">
                        <img src={item.Media[1].MediaURL} alt="house" />
                      </div>
                      <div className="image">
                        <img src={item.Media[2].MediaURL} alt="house" />
                      </div>
                      <div className="image">
                        <img src={item.Media[3].MediaURL} alt="house" />
                      </div>
                    </Carousel>
                  </div>
                  {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={15}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      {/* Child components, such as markers, info windows, etc. */}
                      <></>
                    </GoogleMap>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalInfo;
