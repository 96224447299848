import React, { useState } from "react";
import "../Assets/css/navbar.css";
import Logo from "../Assets/img/logo.png";
import PhoneIcon from "../Assets/img/phone.svg";
import { Navbar, Nav, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const NavBar = () => {
	return (
		<div className="main-menu">
			<div className="logo">
				<a href="/" to="/">
					<img src={Logo} alt="logo" />
				</a>
			</div>
			<div className="menu">
				<Navbar expand="lg">
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto" id="menuu">
							<NavLink
								exact={true}
								className="menulinks"
								to="/"
								activeClassName="main-nav-active"
							>
								Home
							</NavLink>
							<a href="/#about" className="menulinks">
								About
							</a>
							{/* <a className="menulinks" href="/#property">
                Properties
              </a> */}
							<NavLink
								to="/all_properties"
								className="menulinks"
								activeClassName="main-nav-active"
							>
								Properties
							</NavLink>
							<NavLink
								to="/search"
								className="menulinks"
								activeClassName="main-nav-active"
							>
								Search
							</NavLink>
							<NavLink
								to="/blog"
								className="menulinks"
								activeClassName="main-nav-active"
							>
								Blog
							</NavLink>
							<a href="/#contacts" className="menulinks">
								Contact Us
							</a>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
			<div className="phone-num">
				<div className="mb-2 contactbtn">
					<a href="tel:(651) 334-4806">
						<Button className="thisbtn" size="lg">
							<img src={PhoneIcon} alt="phone" />
							(651) 334-4806
						</Button>
					</a>
				</div>
			</div>
		</div>
	);
};

export default NavBar;
