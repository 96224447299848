import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Arrow from "../Assets/img/arrow.svg";
import BlogBackground from "../Assets/img/broker.jpg";
import { NavLink } from "react-router-dom";
import ReactReadMoreReadLess from "react-read-more-read-less";
import axios from "axios";

const AllArticles = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}blog`)
      .then(({ data }) => {
        setArticles(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="articles">
      <Row id="box">
        {articles.map((item) => {
          const mainImage = item.images.find((image) => image.isMain);
          return (
            <Col
              xs
              lg="4"
              key={item.id}
              style={{ marginBottom: "20px" }}
              className="homepage-blog"
              key={item.id}
            >
              <div
                className="recart-block1"
                style={{
                  backgroundImage: mainImage
                    ? `url(${process.env.REACT_APP_API_URL}blog/image/${
                        mainImage?.imageName || BlogBackground
                      })`
                    : BlogBackground,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="descr-article">
                  <p className="descr-text">{item.title}</p>
                  <div className="about-article">
                    <div className="new-invest">
                      <p>new | {item.tag}</p>
                    </div>
                    <div className="date-article">
                      <p>
                        <ReactReadMoreReadLess charLimit={10} readMoreText={""}>
                          {item.created}
                        </ReactReadMoreReadLess>
                      </p>
                      <NavLink to={`/blog/${item.id}`}>
                        <span>
                          <img src={Arrow} alt="arrow" />
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default AllArticles;
