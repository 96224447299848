import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Assets/css/article.css";
import ArticleSlider from "../Components/articleSlider";
import ArticleDescription from "../Components/articleDescription";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";

const Article = () => {
	let { id } = useParams();
	const [article, setArticle] = useState(false);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}blog/${id}`)
			.then(({ data }) => {
				console.log(data);
				setArticle(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div className="Article">
			{/* <ArticleSlider article={article} /> */}
			<ArticleDescription article={article} />
		</div>
	);
};

export default Article;
