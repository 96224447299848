import React from "react";
import Location from "../Assets/img/location_blue.svg";
import Icon1 from "../Assets/img/location_white.svg";
import Icon2 from "../Assets/img/phone.svg";
import Icon3 from "../Assets/img/email.svg";

const ContactsSec = () => {
	return (
		<div className="map-sec" id="contacts">
			<div className="map-location">
				<img src={Location} alt="location img" />
			</div>
			<div className="contact-block">
				<div className="main-contacts">
					<h2>Contact Us</h2>
					<div style={{ display: "flex" }}>
						<img src={Icon1} alt="loc icon" />
						<h5>8300 Arrowwood Ln N Maple Grove MN 55369</h5>
					</div>
					<div style={{ display: "flex" }}>
						<img src={Icon2} alt="call icon" />
						<h5>
							<a href="tel:(651) 334-4806" className="text-white">
								(651) 334-4806
							</a>
						</h5>
					</div>
					<div style={{ display: "flex" }}>
						<img src={Icon3} alt="email icon" />
						<h5>vlukandwa@gmail.com</h5>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactsSec;
