import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "../Assets/css/home.css";
import Info from "../Components/info";
import Latestproperty from "../Components/latestproperty";
import Search from "../Assets/img/search.svg";
import Component from "../Assets/img/Component.svg";
import Contacts from "../Assets/img/contacts.svg";
import Buy from "../Assets/img/buy.png";
import ArrowRight from "../Assets/img/arrow-alt-right.svg";
import AboutImg from "../Assets/img/about_img.png";
import Location from "../Assets/img/location_blue.svg";
import Icon1 from "../Assets/img/location_white.svg";
import Icon2 from "../Assets/img/phone.svg";
import Icon3 from "../Assets/img/email.svg";
import BlogArticle from "../Components/blogArticles";
import Banner from "../Assets/img/banner.png";
import { Element } from "react-scroll";
import AboutBtn from "../Components/aboutBtn";
import ContactsSec from "../Components/conacts";

const Home = () => {
	const [searchZipCode, setSearchZipCode] = useState();
	const handleSearch = (value) => {
		setSearchZipCode(value);
	};

	return (
		<div className="home">
			<div className="find-dream">
				<img src={Banner} alt="banner" className="bannerjpg" />
				<div className="main-titles">
					<Row
						className="justify-content-md-center"
						style={{ margin: "0" }}
					>
						<Col xs lg="10">
							<div className="title">
								<h1>Find your dream </h1>
								<h1>home easily and</h1>
								<h1>comfortably</h1>
								<p>
									Lorem ipsum dolor sit amet, consetetur
									sadipscing elitr, sed diam nonumy eirmod
									tempor invidunt ut labore et dolore magna
									aliquyam erat, sed diam voluptua.
								</p>
							</div>
						</Col>
					</Row>
				</div>
				{/* <Info handleSearch={handleSearch} /> */}
				<Latestproperty zipcode={searchZipCode} />
				<div className="how-works">
					<Row
						className="justify-content-md-center"
						style={{ margin: "0px" }}
					>
						<Col xs lg="10">
							<div>
								<div className="works-title">
									<h6>how it</h6>
									<h2>works</h2>
								</div>
								<div className="imgdivs">
									<div>
										<img
											src={Search}
											alt="search"
											id="imagefst"
										/>
										<h6>STEP 1</h6>
										<h2>CHOOSE PROPERTY</h2>
									</div>
									<div>
										<img
											src={Component}
											alt="component"
											id="comp"
										/>
									</div>
									<div>
										<img
											src={Contacts}
											alt="contacts"
											id="imagesec"
										/>
										<h6>STEP 2</h6>
										<h2>Contact Us</h2>
									</div>
									<div>
										<img
											src={Component}
											alt="component"
											id="comp"
										/>
									</div>
									<div>
										<img
											src={Buy}
											alt="buy"
											id="imagethrd"
										/>
										<h6>STEP 3</h6>
										<h2>Buy your dream house</h2>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<Element name="blog-sec">
					<BlogArticle />
				</Element>
				<div className="about" id="about">
					<Row
						className="justify-content-md-center"
						style={{ margin: "0px" }}
					>
						<Col xs lg="10">
							<Element name="aboutSec">
								<Row className="about-content">
									<Col className="about-info">
										<h3>about</h3>
										<AboutBtn />
									</Col>
									<Col className="aboutimg-div">
										<img src={AboutImg} alt="about img" />
									</Col>
								</Row>
							</Element>
						</Col>
					</Row>
				</div>
				<ContactsSec />
			</div>
		</div>
	);
};

export default Home;
