import React from "react";
import NavBar from "./Components/navbar";
import Home from "./Pages/home";
import Article from "./Pages/article";
import Blogpage from "./Pages/blogpage";
import AllProperties from "./Pages/allProperties";
import Footer from "./Components/footer";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useLocation,
} from "react-router-dom";
import AboutBtn from "./Components/aboutBtn";
import Latestproperty from "./Components/latestproperty";
import ContactsSec from "./Components/conacts";
import Search from "./Pages/search";

function App() {
	const location = useLocation();
	//console.log(location);
	return (
		<div className="App">
			{/* <Router> */}
			<NavBar />
			<Switch>
				<Route path="/" exact>
					<Home />
				</Route>
				<Route path="/blog" exact>
					<Blogpage />
				</Route>
				<Route path="/all_properties" exact>
					<AllProperties />
				</Route>
				<Route path="/search" exact>
					<Search />
				</Route>
				<Route path="/blog/:id">
					<Article />
				</Route>
				<Route path="/about">
					<AboutBtn />
				</Route>
				<Route path="/property">
					<Latestproperty />
				</Route>
				<Route path="/contacts">
					<ContactsSec />
				</Route>
			</Switch>
			{location.pathname != "/all_properties" &&
				location.pathname != "/search" && <Footer />}

			{/* </Router> */}
		</div>
	);
}

export default App;
