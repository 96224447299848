import React, { useEffect } from "react";

const Search = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.id = "idxbroker";
		script.src =
			"https://sanyurealty.idxbroker.com/idx/quicksearchjs.php?widgetid=13833";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(document.querySelector("#idxbroker"));
			document.body.removeChild(
				document.querySelector(".IDX-quicksearchWrapper")
			);
		};
	}, []);
	return <div></div>;
};

export default Search;
