import React, { useEffect, useState } from "react";
import "../Assets/css/latestproperty.css";
import ArrowRight from "../Assets/img/arrow-alt-right.svg";
import { Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Element } from "react-scroll";
import ModalInfo from "./modalInfo";
import { NavLink } from "react-router-dom";
import { LoopCircleLoading } from "react-loadingg";
import PropertyBanner from "../Assets/img/property-img.jpg";

const Latestproperty = ({ zipcode }) => {
	const [properties, setProperties] = useState([]);
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchPosts = async () => {
			// setLoading(true);
			// const res = await axios
			//     .get(`${process.env.REACT_APP_API_URL}statements/all_property`)
			//     .then(({ data: { value } }) => {
			//         setProperties(value.splice(0, 6));
			//         console.log(properties);
			//     });
			setLoading(false);
		};
		fetchPosts();
	}, []);

	const Cols = properties
		.filter((item) => {
			if (zipcode) {
				if (zipcode.length > 4) {
					return item.PostalCode.indexOf(zipcode) !== -1;
				}
			} else return item;
		})
		.map((item) => {
			return (
				<Col
					key={item.ListingId}
					id="allcols"
					lg="4"
					md="4"
					sm="6"
					xs="12"
					style={{ marginBottom: "20px" }}
				>
					<ModalInfo item={item} />
				</Col>
			);
		});
	return (
		<Element name="propertySec">
			{show ? (
				<ModalInfo show={show} onHide={() => setShow(false)} />
			) : null}

			<div className="latestprop" id="property">
				<Row
					className="justify-content-md-center"
					style={{ margin: "0px" }}
				>
					<Col xs lg="10">
						<div className="show-all">
							<div className="latest-title">
								<h5>LATEST</h5>
								<h2>PROPERTIES</h2>
							</div>
							<div className="showdiv">
								<NavLink
									to="/all_properties"
									className="menulinks"
									activeClassName="main-nav-active"
								>
									<Button
										className="showbtn"
										variant="secondary"
										size="lg"
									>
										Show all
										<img
											src={ArrowRight}
											alt="arrow right"
										/>
									</Button>
								</NavLink>
							</div>
						</div>
						<div className="blocks">
							<img src={PropertyBanner} className="img-fluid" />
							{/* <Row style={{ margin: "0" }} id="block1">
								{!Cols.length && zipcode ? (
									<p className="nosuch">
										NO PROPERTY TO DISPLAY IN THIS ZIP CODE
									</p>
								) : loading ? (
									<div className="loader">
										<LoopCircleLoading />
									</div>
								) : (
									Cols
								)}
							</Row> */}
						</div>
					</Col>
				</Row>
			</div>
		</Element>
	);
};

export default Latestproperty;
