import React from "react";
import "../Assets/css/info.css";
import { Row, Col } from "react-bootstrap";
import useSearchform from "./useSearchform";
import validate from "./validateSearch";

const Info = ({ handleSearch }) => {
  const { handleChange, values, handleSubmit, errors } = useSearchform(
    validate
  );

  return (
    <div className="Info">
      <div className="searching">
        <Row className="justify-content-md-center thisrow">
          <Col xs lg="10">
            <form onSubmit={handleSubmit}>
              <div className="searchDiv">
                <input
                  type="text"
                  name="zipcode"
                  placeholder="Zip Code"
                  value={values.zipcode}
                  onChange={handleChange}
                />
                <button
                  className="search-btn"
                  type="submit"
                  onClick={() => handleSearch(values.zipcode)}
                  // disabled={!values.zipcode.length}
                >
                  search
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Info;
