import React from "react";
import BlogImg from "../Assets/img/blog_image.png";
import "../Assets/css/blogpage.css";
import { Row, Col, Dropdown, Pagination } from "react-bootstrap";
import RecentArticle from "../Components/recentArticle.js";
import AllArticles from "../Components/allArticles.js";
import News from "../Assets/img/news.svg";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Facebook from "../Assets/img/facebook.svg";
import Twitter from "../Assets/img/twitter.svg";
import Linkedin from "../Assets/img/linkedin.svg";

const Blogpage = () => {
  return (
    <div className="blogpage">
      <div className="blog">
        <Row className="justify-content-md-center" style={{ margin: "0" }}>
          <Col xs lg="10">
            <div className="blog-div">
              <div className="blog-title">
                <h1>Blog</h1>
                <h2>Lorem ipsum dolor sit amet, sed diam nonumy</h2>
              </div>
              <div className="blogimg-div">
                <img src={BlogImg} alt="blog img" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <div className="rec-article">
        <Row className="justify-content-md-center" style={{ margin: "0" }}>
          <Col xs lg="10">
            <div className="article-title">
              <h3>Recent</h3>
              <h1>articles</h1>
            </div>
            <div>
              <RecentArticle />
            </div>
          </Col>
        </Row>
      </div> */}
      <div className="all-article">
        <Row className="justify-content-md-center" style={{ margin: "0" }}>
          <Col xs lg="10">
            <div className="all-article-tit">
              <div className="article-title">
                <h3>All</h3>
                <h1>articles</h1>
              </div>
              {/* <div>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="article-btn">
                    <img src={News} alt="news" />
                    Featured
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="down-menu">
                    <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </div>
            <div>
              <AllArticles />
            </div>
          </Col>
        </Row>
      </div>
      <div className="foot">
        <Pagination>
          <Pagination.Item>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>
          <Pagination.Item>{4}</Pagination.Item>
        </Pagination>
        <Router>
          <Link href="/" className="nextt" to={"/"}>
            NEXT
          </Link>
        </Router>
      </div>
    </div>
  );
};

export default Blogpage;
